import React, { useContext, useState } from 'react'
import classNames from 'classnames'
import './Layout.css'
import profileImage from './profile.jpg'
import menu from '../../configs/menuNavigations'
import { Link, useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import Login from '../Login/Login'
import { ContextLayout } from '../../utility/LayoutContext'
import Header from './Header'

const { confirm } = Modal

function Sidebar({ children }) {
    let { setToken, setUser,user } = useContext(ContextLayout)

    let [open, setOpen] = useState(false)
    let { token } = useContext(ContextLayout)
    let history = useHistory()

    function showConfirm() {
        confirm({
            cancelText: "Vazgeç",
            okText: "Evet",
            icon: <ExclamationCircleOutlined />,
            content: "Çıkmak istiyor musunuz?",
            onOk() {
                logout()
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    let logout = () => {
        Cookies.remove("token")
        setToken(null)
        localStorage.removeItem("user")
        setUser(null)
        history.push("/login")
    }


    if (token) return (
        <div className="sidebar-container">
            <div className={classNames("sidebar", open ? "open" : "")}>
                <div className="logo-details">
                    <i className='bx bx-mobile-alt icon'></i>
                    <div className="logo_name">Şamlıoğlu</div>
                    <i className='bx bx-menu' id="btn" onClick={() => setOpen(!open)}  ></i>
                </div>
                <ul className="nav-list">
                    {menu.map((item, i) =>
                        <li key={i}>
                            <Link to={`/${item.path}`} >
                                <i className={item.icon}></i>
                                <span className="links_name">{item.title}</span>
                            </Link>
                            <span className="tooltip">{item.title}</span>
                        </li>
                    )}

                    <li className="profile">
                        <div className="profile-details">
                            <img src={profileImage} alt="profileImg" />
                            <div className="name_job">
                                <div className="name">{user?.name}</div>
                                <div className="job">{user?.lastname}</div>
                            </div>
                        </div>
                        <i className='bx bx-log-out cursor-pointer' id="log_out" onClick={() => showConfirm()} ></i>
                    </li>
                </ul>
            </div>
            <section className="home-section">
                <Header open={open} setOpen={setOpen} />
                <div className="view-container">
                    {children}
                </div>
            </section>

        </div>
    )
    else
        return (
            <Login></Login>
        )
}

export default Sidebar
