import React, { useContext, useState } from 'react'
import { Spin, Table, Tabs, Tag } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import CurrencyLogos from '../../configs/currencyLogos.js'
import { ContextLayout } from '../../utility/LayoutContext.js';
import classNames from 'classnames';
let { Column } = Table

const AllCurrencyList = (props) => {
    const { liveDatas, settings } = useContext(ContextLayout)
    let [activeTab, setActiveTab] = useState("altin")




    if (!liveDatas.length)
        return <div className="vw-100 vh-100 d-flex justify-content-center align-items-center"><Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 64 }} spin />} ></Spin></div>
    return (<div>
        <header className="pt-4 w-100 text-center full-page-header">
        </header>
        <section>
            <nav className="d-flex w-100">
                <div className={classNames("curreny-tabpage", activeTab === "altin" ? "active" : "")} onClick={() => setActiveTab("altin")}>ALTIN / EMTİA</div>
                <div className={classNames("curreny-tabpage", activeTab !== "altin" ? "active" : "")} onClick={() => setActiveTab("money")}>DÖVİZ</div>
            </nav>
            <Table rowClassName="custom-table-row" bordered={false} rowKey="label" rowClassName={(record) => record.status} dataSource={liveDatas.filter(l => activeTab === "altin" ? (l.type === "emtia") : (l.type === "doviz" || l.type === "money"))} pagination={false} size="large">
                <Column className="currency label-column" align="left" dataIndex="key" key="key"
                    render={(label, row) => <div className="w-100 d-flex align-items-center justify-content-start"><img style={{ marginRight: 15 }} width="40px" src={CurrencyLogos[row.key] || CurrencyLogos.ALTIN}></img><span>{label}</span></div>} />
                <Column className="money-price other-column" align="center" dataIndex="salesPrice" key="label" title="Alış" />
                <Column className="money-price" align="center" dataIndex="purchasePrice" key="label" title="Satış" />
                <Column className="money-price highest-price" align="center" dataIndex="highest" key="label" title="En Yüksek" />
                <Column className="money-price lowest-price" align="center" dataIndex="lowest" key="label" title="En Düşük" />
                <Column className="money-price" align="center" dataIndex="differance" key="label" title="Fark" render={(dif) => dif == 0 ?
                    <div className="differance" style={{ color: "#096dd9" }}>
                        <img className="differance-logo" src={CurrencyLogos.STABIL} />
                        <span>{dif}</span>
                    </div> :
                    dif < 0 ?
                        <div className="differance" style={{ color: "#cf1322" }}>
                            <img className="differance-logo" src={CurrencyLogos.DOWN} />
                            <span>{dif}</span>
                        </div> :
                        <div className="differance" style={{ color: "#389e0d" }}>
                            <img className="differance-logo" src={CurrencyLogos.UP} />
                            <span>{dif}</span>
                        </div>} />
            </Table>
        </section>
    </div >);
}
export default AllCurrencyList
