import React, { lazy, Suspense, useState, useEffect } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom"
import { ContextLayout } from "./utility/LayoutContext";
import Cookies from "js-cookie";
import DashboardLayoutRoute from "./routers/DashboardLayoutRoute";
import FullLayoutRoute from "./routers/FullLayoutRoute";
import './App.css';
import Users from "./pages/Users/List"
import UserView from "./pages/Users/View"
import AllCurrencyList from "./pages/AllCurrencyList"
import Currencies from "./pages/Currencies/index"
import Notifications from "./pages/Notifications/index"
import Board from "./pages/Boards/index"
import SettingPage from "./pages/Settings"
import Singleton from "./utility/socketControl"
import PanelView from './pages/Boards/view'

const App = () => {
  const socket = Singleton.getInstance()
  let [user, setUser] = useState(null)
  let [token, setToken] = useState(Cookies.get("token"))
  let [loading, setLoading] = useState(true)
  let [settings, setSettings] = useState(null)
  const [liveDatas, setLiveDatas] = useState([])


  useEffect(() => {
    if (settings && settings.currencies) {
      socket.emit("join", { user: "123", token: "123" }, (data, err) => {
        if (err) {
          this.props.history.push('/');
        }

        if (Array.isArray(data)) {
          const filteredData = data.filter(d => d)
          setLiveDatas(settings.currencies.map(ct => {
            ct.new = false
            if (filteredData.some(m => m.key === ct.key)) {
              const newData = filteredData.find(m => m.key === ct.key)
              ct = { ...ct, ...newData }
            }
            return ct
          })
          )
        }
      });

      socket.on('live-data', async (data) => {
        if (Array.isArray(data))
          setLiveDatas((liveData) => settings.currencies.map(ct => {
            ct.new = false
            if (data.some(m => m.key === ct.key)) {
              const newData = data.find(m => m.key === ct.key)
              ct.new = true
              ct = { ...ct, ...newData }
            }
            else {
              ct.salesPrice = liveData.find(m => m.key === ct.key)?.salesPrice
              ct.purchasePrice = liveData.find(m => m.key === ct.key)?.purchasePrice
              ct.highest = liveData.find(m => m.key === ct.key)?.highest
              ct.lowest = liveData.find(m => m.key === ct.key)?.lowest
              ct.closing = liveData.find(m => m.key === ct.key)?.closing
              ct.differance = liveData.find(m => m.key === ct.key)?.differance
            }
            return ct
          }))
      });
    }
  }, [settings])

  return (
    <ContextLayout.Provider value={{ liveDatas, user, setUser, token, setToken, loading, setLoading, settings, setSettings }}>
      <BrowserRouter>
        <Switch>
          {/* <Route exact path="/"><Redirect to="/login" /></Route> */}
          <FullLayoutRoute exact path="/" component={AllCurrencyList} />
          <FullLayoutRoute exact path="/panel/:id" component={PanelView} />
          <DashboardLayoutRoute exact path="/currencies" component={Currencies} />
          <DashboardLayoutRoute exact path="/board" component={Board} />
          <DashboardLayoutRoute exact path="/notifications" component={Notifications} />
          <DashboardLayoutRoute exact path="/settings" component={SettingPage} />
          <DashboardLayoutRoute exact path="/users" component={Users} />
          <DashboardLayoutRoute exact path="/users/:id" component={UserView} />
          <DashboardLayoutRoute exact path="*" component={AllCurrencyList} />
        </Switch>
      </BrowserRouter>
    </ContextLayout.Provider>
  );
}

export default App;
