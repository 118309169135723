import React, { useContext, useEffect, useState } from 'react'
import { Input, Form, message, Button } from 'antd'
import { Row, Col, Card, CardBody, CardHeader, CardTitle, Label } from 'reactstrap'
import { useGetData, usePostData, useUploadFile } from '../hooks/serviceGetways';
import SingleFileUpload from '../components/SingleFileUpload'
import { ContextLayout } from '../utility/LayoutContext';

const tailLayout = {
    labelCol: { span: 0 },
    wrapperCol: { offset: 0, span: 24 },
};

function Settings() {
    let { user, setUser } = useContext(ContextLayout)
    let [userInfo, setUserInfo] = useState(null)
    let [logo, setLogo] = useState(null)
    let [loading, setLoading] = useState(false)
    let [upload, progress] = useUploadFile()
    let getData = useGetData()
    let postData = usePostData()

    useEffect(() => {

        getData("me", {}).then(({ result, resultMessage }) => {
            setUserInfo(result)
        }).catch(err => message.error(err.message))

        getData("settings", {}).then(({ result, resultMessage }) => {
            setLogo(result.logo)
        })
    }, [])

    let uploadMedia = async () => {
        let { result, resultMessage } = await upload({ files: [userInfo.avatar] })
        if (result)
            return result[0]
        else
            message.error(resultMessage)
    }

    let save = async () => {
        let medias = null

        if (userInfo.avatar && userInfo.avatar.uid) {
            medias = await uploadMedia()
        }
        else if (userInfo.avatar && userInfo.avatar)
            medias = userInfo.avatar

        postData("me", { ...userInfo, avatar: medias }).then(({ result, resultMessage }) => {
            if (result)
                message.success("Profiliniz başarılı bir şekilde güncellendi.")
        })
    }


    let uploadLogo = async () => {
        let { result, resultMessage } = await upload({ files: [logo.logo] })
        if (result)
            return result[0]
        else
            message.error(resultMessage)
    }


    let updateLogo = async () => {
        let logoImage = !logo.logo.url ? await uploadLogo() : logo

        postData("updateLogo", { logo: logoImage }).then(({ result, resultMessage }) => {
            if (result)
                message.success("Logo başarılı bir şekilde güncellendi.")
        })
    }

    return (
        <div>
            <Row className="mb-2">
                <Col sm="12" md="12" lg={{ size: 8, offset: 0 }}  >
                    <Card>
                        <CardHeader>
                            <CardTitle>
                                <div>Profil Bilgileri</div>
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Form layout="horizontal" labelAlign="left" size="middle">
                                <Row className="mx-0" col="12">
                                    <Col className="pl-0" sm="12" md={{ size: 6, offset: 0 }}  >
                                        <Form.Item {...tailLayout}>
                                            <Label for="phone">Adı</Label>
                                            <Input placeholder="Adı" value={userInfo?.name} onChange={(e) => { setUserInfo({ ...userInfo, name: e.target.value }) }}></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col className="pl-0" sm="12" md={{ size: 6, offset: 0 }}  >
                                        <Form.Item  {...tailLayout}>
                                            <Label for="phone">Soyadı</Label>
                                            <Input placeholder="Soyadı" value={userInfo?.lastname} onChange={(e) => { setUserInfo({ ...userInfo, lastname: e.target.value }) }}></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row className="mx-0" col="12">
                                    <Col className="pl-0" sm="12" md={{ size: 6, offset: 0 }}  >
                                        <Form.Item {...tailLayout}>
                                            <Label for="phone">Email</Label>
                                            <Input type="email" placeholder="Email" disabled value={userInfo?.email} onChange={(e) => { setUserInfo({ ...userInfo, email: e.target.value }) }}></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row className="mx-0" col="12">
                                    <Col className="pl-0" sm="6" md={{ size: 6, offset: 0 }}>
                                        <Form.Item {...tailLayout}>
                                            <Label for="phone">Profil Fotosu</Label>
                                            {userInfo && <SingleFileUpload setRecord={setUserInfo} record={userInfo} name="avatar" />}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="mr-0">
                                    <Col xs="12" sm="12" md={{ size: 3, offset: 9 }}>
                                        <Button type="primary" style={{ width: "100%", justifyContent: "center" }} onClick={() => save()}> Güncelle</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="12" lg={{size:4}}>
                    <Card>
                        <CardHeader>
                            <CardTitle>
                                <div>Uygulama Bilgileri</div>
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row className="mx-0" col="12">
                                <Col className="pl-0" sm="12">
                                    <Label for="phone">Logo (* Sidebar açık logo )</Label>
                                    {logo && <SingleFileUpload setRecord={setLogo} record={logo} name="logo" />}
                                </Col>
                            </Row>

                            <Row className="mr-0">
                                <Col xs="12" sm="12" md={{ size: 3, offset: 9 }}>
                                    <Button type="primary" style={{ width: "100%", justifyContent: "center" }} onClick={() => updateLogo()}> Güncelle</Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                </Col>

            </Row>

        </div >
    )
}

export default Settings