import React, { useContext, useState } from 'react'
import { Button, Input } from 'antd'
import AvatarImage from '../../assets/img/avatar.svg'
import BgImage from '../../assets/img/login-left.svg'
import WaveImage from '../../assets/img/wave2.png'
import "./Login.css"
import { Link, useHistory } from 'react-router-dom'
import { ContextLayout } from '../../utility/LayoutContext'
import { usePostData } from '../../hooks/serviceGetways'
import Cookies from "js-cookie"
import { message, Form } from "antd"

const Login = () => {
    let { setToken, setUser } = useContext(ContextLayout)
    let postData = usePostData()
    let history = useHistory()
    let [email, setEmail] = useState(null)
    let [password, setPassword] = useState(null)

    let login = () => {
        if (!email || email === "" || !password || password === "") {
            message.error("Kullanıcı adı ve şifre boş olamaz")
            return false
        }
        postData("login", { email, password }).then(({ result, result_message }) => {
            if (result) {
                Cookies.set("token", result.token)
                setToken(result.token)
                localStorage.setItem("user", JSON.stringify({ name: result.name, avatar: result.avatar }))
                setUser({ name: result.name, avatar: result.avatar, role: result.role })
                window.scrollTo({ top: 0, behavior: 'smooth' })
                history.push("/currencies")
            }
        }).catch(err => {
            message.error(err.message)
        })
    }

    return (
        <div>
            <img className="wave" src={WaveImage} />
            <div className="login-container">
                <div className="img">
                    <img src={BgImage} />
                </div>
                <div className="login-content">
                    <div className="form">
                        <img src={AvatarImage} />
                        <h2 className="title">Welcome</h2>
                        <div className="input-div">
                            <i className='bx bx-user mr-1' ></i>
                            <Input type="text" onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="input-div">
                            <i className='bx bxs-lock mr-1' ></i>
                            <Input type="password" onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <Link to="/register">Şifremi Unuttum?</Link>
                        <Button type="link" style={{ background: "#3A3868", color: "#fff", fontSize: "16px", marginTop: 5 }} onClick={login}> GİRİŞ YAP</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
