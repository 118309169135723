import React, { useState, useCallback, useEffect } from 'react'
import { Button, Divider, Input, Popconfirm, Table, Modal, Form, message, Switch, DatePicker } from 'antd'
import SingleFileUpload from '../../components/SingleFileUpload'
import { Label } from 'reactstrap'
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, EditOutlined, PlusCircleFilled } from '@ant-design/icons'
import { useDeleteData, useGetData, usePostData, usePutData, useUploadFile } from '../../hooks/serviceGetways'
import moment from 'moment'
let { Column } = Table


let EditModal = (props) => {
    let { record, setRecord } = props
    let postData = usePostData()
    let putData = usePutData()
    let [upload, progress] = useUploadFile()

    let validate = useCallback(() => {
        if (!record) return {};
        let errors = {};
        if (record.title == null || record.title.length === 0)
            errors.title = 'Zorunlu Alan!'

        if (record.description == null || record.description.length === 0)
            errors.description = 'Zorunlu Alan!'

        if (record.active == null || record.active.length === 0)
            errors.active = 'Zorunlu Alan!'

        if (record.shareDate == null || record.shareDate.length === 0)
            errors.shareDate = 'Zorunlu Alan!'

        errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
        errors.hasError = errors.all.length > 0;
        return errors;
    }, [record]);

    let [errors, setErrors] = useState({})

    useEffect(() => { setErrors(validate()) }, [record, validate]);



    let save = async () => {
        if (record._id === "new")
            postData("notifications", { ...record }).then(({ result, resultMessage }) => {
                if (result)
                    message.success("Kayıt başarılı bir şekilde eklendi.")
                setRecord(null)
            })
        else
            putData("notifications", { ...record }).then(({ result, resultMessage }) => {
                if (result)
                    message.success("Kayıt başarılı bir şekilde güncellendi.")
                setRecord(null)
            })

    }
    return (
        <Modal title={record && record._id === "new" ? "Bildirim Ekle" : "Bildirim Düzenle"}
            okText={record && record._id === "new" ? "Ekle" : "Düzenle"}
            cancelText="Vazgeç"
            visible={true}
            style={{ width: "45%", minWidth: 600 }}
            onOk={save}
            footer={[
                <Button key="1" type="default" onClick={() => setRecord(null)}>
                    Vazgeç
                </Button>,
                <Button key="2" type="primary" onClick={save}
                    disabled={errors.hasError}>
                    {record && record._id === "new" ? "Ekle" : "Düzenle"}
                </Button>

            ]}
            onCancel={() => setRecord(null)}>
            <Form labelCol={{ xs: 5 }} wrapperCol={{ xs: 19 }} labelAlign="left">
                <Form.Item key="1" label='Başlık' help={errors ? errors.title : ""} validateStatus={errors && errors.title ? 'error' : 'success'}>
                    <Input value={record?.title}
                        required={true}
                        onChange={e => setRecord({ ...record, title: e.target.value })} />
                </Form.Item>

                <Form.Item key="2" label='Detay' help={errors ? errors.description : ""} validateStatus={errors && errors.description ? 'error' : 'success'}>
                    <Input value={record?.description}
                        required={true}
                        onChange={e => setRecord({ ...record, description: e.target.value })} />
                </Form.Item>

                <Form.Item key="4" label='Paylaşım Tarihi' help={errors ? errors.shareDate : ""} validateStatus={errors && errors.shareDate ? 'error' : 'success'}>
                    <DatePicker value={moment(record.shareDate)} className="w-100" showTime format="DD.MM.YYYY HH:mm" onChange={(v) => setRecord({ ...record, shareDate: moment(v).toDate() })} />
                </Form.Item>

                <Form.Item key="3" label='Aktif' help={errors ? errors.active : ""} validateStatus={errors && errors.active ? 'error' : 'success'}>
                    <Switch checked={record.active} checkedChildren="Aktif" unCheckedChildren="Pasif" onChange={(v) => setRecord({ ...record, active: v })} />
                </Form.Item>
            </Form>
        </Modal>
    )
}

const Notifications = () => {
    let [datas, setDatas] = useState([])
    let [record, setRecord] = useState(null)
    let [loading, setLoading] = useState(false)
    let getData = useGetData()
    let deleteData = useDeleteData()

    let deleted = (id) => {
        deleteData("notifications/" + id, {}).then(({ result }) => {
            if (result) {
                setLoading(true)
                getData("notifications/list", {}).then((data) => {
                    setDatas(data.result?.rows || [])
                    setLoading(true)
                })
                message.success("Kayıt başarılı bir şekilde silindi.")
            }
        })
    }


    useEffect(() => {
        if (record === null) {
            setLoading(true)
            getData("notifications/list", {}).then(({ result, resultMessage }) => {
                setDatas(result.rows || [])
                setLoading(false)
            })
        }
    }, [record])

    return (<>
        {record && <EditModal record={record} setRecord={setRecord}></EditModal>}
        <div className="d-flex align-items-center justify-content-end mb-1">
            <Button icon={<PlusCircleFilled />} onClick={() => setRecord({ _id: "new", active: true, shareDate: new Date() })}> Bildirim Ekle</Button>
        </div>

        <div className="d-block">
            <Table dataSource={datas} rowKey="_id" pagination={true} loading={loading}>
                <Column dataIndex="title" key="title" title="Başlık" width="30%" align="center"></Column>
                <Column dataIndex="description" key="description" title="Detay" align="center" width="30%"></Column>
                <Column dataIndex="shareDate" key="shareDate" title="Paylaşım Tarihi" align="center" width="30%" render={(date)=>moment(date).format("DD.MM.YYYY HH:mm")}></Column>
                <Column dataIndex="active" key="active" title="Durumu" align="center" render={(val, record) => val ? <CheckCircleOutlined style={{ fontSize: 18 }} /> : <CloseCircleOutlined style={{ fontSize: 18 }} />}></Column>
                <Column dataIndex="edit" key="edit" title="Düzenle" align="center" width="150px" render={(text, recordData) => (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button icon={<EditOutlined />} onClick={() => { setRecord(recordData) }}></Button>
                        <Divider type="vertical" ></Divider>
                        <Popconfirm
                            onConfirm={() => deleted(recordData._id)} title="Silmeyi Onaylıyor musunuz?"
                            okText="Onayla" cancelText="Vazgeç">
                            <Button type="danger" icon={<DeleteOutlined />}>
                            </Button>
                        </Popconfirm>
                    </div>
                )}></Column>
            </Table>

        </div>
    </>
    )
}

export default Notifications
