import React from 'react'

const Header = (props) => {
    let { open, setOpen } = props
    return (
        <div className="header-container">
            <i className='d-block d-lg-none bx bx-menu' id="btn" onClick={() => setOpen(!open)}  ></i>
            <div className="text-center" style={{ fontSize: 20, fontWeight: 500, marginTop: 10 }}>{!open ? 'Şamlıoğlu Grup' : ""}</div>
        </div>
    )
}

export default Header
