import React, { useCallback, useEffect, useState } from "react"
import { Button, message, Popconfirm, Table, Divider, Pagination, Card } from "antd"
import { useDeleteData, useGetData } from "../../hooks/serviceGetways"
import { PlusCircleFilled, DeleteOutlined, EditOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { IoCogOutline, IoLogoInstagram, IoMailOpenOutline, IoPeopleOutline, IoPhonePortraitOutline } from "react-icons/io5";

const { Column } = Table;

let UserList = () => {
    let history = useHistory()
    let [users, setUsers] = useState([])
    let [loadPage, setLoadPage] = useState(false)
    let [pagination, setPagination] = useState({ page: 0, pageSize: 10, total: 0 })
    let getData = useGetData()
    let deleteData = useDeleteData()

    let getUsers = useCallback(() => {
        getData("users/list", { query: { ...pagination } }).then(({ result, resultMessage }) => {
            setUsers(result.rows)
            setPagination({ page: result.page, pageSize: result.pageSize, total: result.total })
            setLoadPage(true)
        }).catch(err => {
            message.error(err.message)
        })
    }, [])

    useEffect(() => {
        getUsers()
    }, [])


    useEffect(() => {
        if (loadPage) {
            getData("users/list", { query: { ...pagination } }).then(({ result, resultMessage }) => {
                setUsers(result.rows)
            }).catch(err => {
                message.error(err.message)
            })
        }
    }, [pagination])

    let deleteUsers = (typeId) => {
        deleteData("users/" + typeId, {}).then(({ result, resultMessage }) => {
            if (result)
                message.success("Kullanıcı başarılı bir şekilde silindi.")
            getUsers()
        }).catch(err => {
            message.error(err.message)
        })
    }


    return <>
        <div className="d-none d-md-block d-lg-block d-xl-block">
            <Table dataSource={users} rowKey="_id" scroll={{ x: 780 }} pagination={false}>
                <Column dataIndex="name" key="name" title="Kullanıcı Adı" width="200px" render={(val, record) => record.name + " " + record.lastname}></Column>
                <Column dataIndex="companyName" key="companyName" title="Firma Adı"></Column>
                <Column dataIndex="email" key="email" title="Email" align="center"></Column>
                <Column dataIndex="phone" key="phone" title="Telefon" align="center"></Column>
                <Column dataIndex="active" key="active" title="Durumu" align="center" render={(val, record) => val ? <CheckCircleOutlined style={{ fontSize: 18 }} /> : <CloseCircleOutlined style={{ fontSize: 18 }} />}></Column>
                <Column dataIndex="edit" key="edit" title="Düzenle" align="center" width="150px" render={(text, record) => (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Link to={"/users/" + record._id}>
                            <Button icon={<EditOutlined />}>
                            </Button>
                        </Link>
                        <Divider type="vertical" ></Divider>
                        <Popconfirm
                            onConfirm={() => deleteUsers(record._id)} title="Silmeyi Onaylıyor musunuz?"
                            okText="Onayla" cancelText="Vazgeç">
                            <Button type="danger" icon={<DeleteOutlined />}>
                            </Button>
                        </Popconfirm>
                    </div>
                )}></Column>
            </Table>

        </div>
        <div>
            {users.map(user =>
                <Card key={user._id} className="d-xxl-none d-xl-none d-lg-none d-md-none d-sm-block mb-3">
                    <div className="d-table justify-content-start align-items-center">
                        <div className="d-block mb-2">
                            <IoPeopleOutline className="mr-1" />
                            <span>{user.name}</span>
                        </div>

                        <div className="d-block mb-2">
                            <IoPeopleOutline className="mr-1" />
                            <span>{user.companyName}</span>
                        </div>

                        <div className="d-block mb-2">
                            <IoPhonePortraitOutline className="mr-1" />
                            <span>{user.phone}</span>
                        </div>

                        <div className="d-block mb-2">
                            <IoMailOpenOutline className="mr-1" />
                            <span>{user.email}</span>
                        </div>


                        <div className="d-block mb-2">
                            <IoCogOutline className="mr-1" />
                            <span>Durumu : {user.active ? <CheckCircleOutlined style={{ fontSize: 18 }} /> : <CloseCircleOutlined style={{ fontSize: 18 }} />}</span>
                        </div>

                        <div className="d-flex mb-2">
                            <Link to={"/users/" + user._id}>
                                <Button icon={<EditOutlined />}>
                                    Düzenle
                                </Button>
                            </Link>
                            <div className="mx-2"></div>
                            <Popconfirm
                                onConfirm={() => deleteUsers(user._id)} title="Silmeyi Onaylıyor musunuz?"
                                okText="Onayla" cancelText="Vazgeç">
                                <Button type="danger" icon={<DeleteOutlined />}>
                                    Sil
                                </Button>
                            </Popconfirm>
                        </div>
                    </div>
                </Card>)}
        </div>
        <div className="w-100 d-flex justify-content-end align-items-center mt-2">
            <Pagination showTotal={total => `Toplam : ${total} `}
                showSizeChanger
                current={pagination.page}
                total={pagination.total}
                pageSize={pagination.pageSize}
                onChange={(p, ps) => setPagination({ ...pagination, page: p, pageSize: ps })}
            />
        </div>
    </>

}

export default UserList