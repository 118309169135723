import axios from 'axios'
import React, { useContext, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { ContextLayout } from '../utility/LayoutContext'

const FullPageRouter = ({ component: Component, ...rest }) => {
    let { setSettings } = useContext(ContextLayout)
    useEffect(() => {
        axios.get(`https://exchange.justalp.com/api/settings`, {}).then(({ data: { result } }) => {
            setSettings(result)
        })
    }, [])
    return (
        <Route {...rest} render={props => (
            <div>
                <Component {...props} />
            </div>
        )} />
    )
}

export default FullPageRouter
