/* eslint-disable no-throw-literal */
import { useContext, useMemo, useState } from 'react'
import axios from 'axios'
import qs from 'qs';
import { ContextLayout } from '../utility/LayoutContext';
import Cookies from 'js-cookie'

let host = 'https://exchange.justalp.com/api'

let header = {
    "Content-Type": "application/json"
}

let useGetData = () => {
    let { token, setToken } = useContext(ContextLayout)
    let call = useMemo(() => async (action, params) => {
        if (token) {
            header.Authorization = `Bearer ${token}`
        }

        let { query } = params;
        try {
            let { data: { result, resultMessage } } = await axios.get(query ? `${host}/${action}?${qs.stringify(query)}` : `${host}/${action}`, {
                headers: header
            })
            if (resultMessage && resultMessage.type === 'token_expire') {
                Cookies.remove("token")
                setToken(null);
                return (resultMessage.message)
            }
            else if (!result && resultMessage.type === 'error')
                throw Error(resultMessage.message)
            else
                return ({ result, resultMessage })

        }
        catch (error) {
            if (error) {
                if (error?.response?.status === 404)
                    throw ({ code: 404, message: '404 Aradığınızı bulamadık.' })
                else if (error?.message === 'Entity not found')
                    throw ({ code: 0, message: 'Aradığınız kayıt bulunamadı.' })
                else
                    throw ({ code: error.response.data.status, message: error.response.data.errorMessage.message })
            }
        }

    }, [host])

    return call

}

let usePostData = () => {
    let { token, setToken } = useContext(ContextLayout)
    let call = useMemo(() => async (action, values) => {
        try {
            if (action !== "login" && token) {
                header.Authorization = `Bearer ${token}`
            }

            let { data: { result, resultMessage, errorMessage } } = await axios.post(`${host}/${action}`, values, {
                headers: header
            })
            if (errorMessage && errorMessage.type === 'token_expire') {
                Cookies.remove("token")
                setToken(null);
                return (errorMessage.message)
            }
            else if (!result && errorMessage.type === 'error')
                throw (errorMessage.message)
            else
                return ({ result, resultMessage })

        }
        catch (error) {
            if (error) {
                if (error?.response?.status === 404)
                    throw ({ code: 404, message: '404 Aradığınızı bulamadık.' })
                else if (error?.message === 'Entity not found')
                    throw ({ code: 0, message: 'Aradığınız kayıt bulunamadı.' })
                else
                    throw ({ code: error.response.data.status, message: error.response.data.errorMessage.message })
            }
        }

    }, [host])

    return call

}

let usePutData = () => {
    let { token, setToken } = useContext(ContextLayout)

    let call = useMemo(() => async (action, values) => {
        try {

            if (token) {
                header.Authorization = `Bearer ${token}`
            }

            let { data: { result, resultMessage } } = await axios.put(`${host}/${action}`, values, {
                headers: header
            })
            if (resultMessage && resultMessage.type === 'token_expire') {
                Cookies.remove("token")
                setToken(null);
                return (resultMessage.message)
            }
            else if (!result && resultMessage.type === 'error')
                throw Error(resultMessage.message)
            else
                return ({ result, resultMessage })

        }
        catch (error) {
            if (error) {
                if (error?.response?.status === 404)
                    throw ({ code: 404, message: '404 Aradığınızı bulamadık.' })
                else if (error?.message === 'Entity not found')
                    throw ({ code: 0, message: 'Aradığınız kayıt bulunamadı.' })
                else
                    throw ({ code: error.response.data.status, message: error.response.data.errorMessage.message })
            }
        }

    }, [host])

    return call

}

let useDeleteData = () => {
    let { token, setToken } = useContext(ContextLayout)

    let call = useMemo(() => async (action) => {
        try {
            if (token) {
                header.Authorization = `Bearer ${token}`
            }

            let { data: { result, resultMessage } } = await axios.delete(`${host}/${action}`, {
                headers: header
            })

            if (resultMessage && resultMessage.type === 'token_expire') {
                Cookies.remove("token")
                setToken(null);
                return (resultMessage.message)
            }
            else if (!result && resultMessage.type === 'error')
                throw Error(resultMessage.message)
            else
                return ({ result, resultMessage })

        }
        catch (error) {
            if (error) {
                if (error?.response?.status === 404)
                    throw ({ code: 404, message: '404 Aradığınızı bulamadık.' })
                else if (error?.message === 'Entity not found')
                    throw ({ code: 0, message: 'Aradığınız kayıt bulunamadı.' })
                else
                    throw ({ code: error.response.data.status, message: error.response.data.errorMessage.message })
            }
        }

    }, [host])

    return call

}

let useUploadFile = () => {
    let { token, setToken } = useContext(ContextLayout)


    const [progress, setProgress] = useState(0);
    let upload = useMemo(() => async (values) => {
        if (token) {
            try {
                const formData = new FormData();
                values.files.forEach(file => formData.append('files', file));

                let { data: { result, resultMessage } } = await axios.post(`${host}/upload`, formData, {
                    headers: {
                        'ContenType': 'multipart/form-data',
                        authorization: `Bearer ${token}`
                    },
                    onUploadProgress: event => {
                        const percent = Math.floor((event.loaded / event.total) * 100);
                        setProgress(percent - 1);
                    }
                })

                if (resultMessage && resultMessage.type === 'token_expire') {
                    Cookies.remove("token")
                    setToken(null);
                    return (resultMessage.message)
                }
                else if (!result && resultMessage.type === 'error')
                    throw Error(resultMessage.message)
                else
                    return ({ result, resultMessage })
            }
            catch (error) {
                if (error) {
                    if (error?.response?.status === 404)
                        throw ({ code: 404, message: '404 Aradığınızı bulamadık.' })
                    else if (error?.message === 'Entity not found')
                        throw ({ code: 0, message: 'Aradığınız kayıt bulunamadı.' })
                    else
                        throw ({ code: error.response.data.status, message: error.response.data.errorMessage.message })
                }
            }
        }
        else {
            throw new Error({ status: 404 })
        }
    }, [token])

    return [upload, progress]

}

export { useGetData, usePostData, usePutData, useDeleteData, useUploadFile }
