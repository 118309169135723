import React, { useContext, useEffect, useState } from 'react'
import { Button, Spin } from 'antd';
import { FullscreenExitOutlined, FullscreenOutlined, LoadingOutlined } from '@ant-design/icons';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import CloudyDay from '../../assets/img/cloudy-day.svg'
import Overcast from '../../assets/img/overcast-clouds.png'
import Sunny from '../../assets/img/sunny.svg'
import ALTIN from '../../assets/images/ALTIN.png'
import DOWN from '../../assets/images/DOWN.png'
import UP from '../../assets/images/UP.png'
import STABIL from '../../assets/images/STABIL.png'
import MAYDOVIZ from '../../assets/images/MAY.png'
import { ContextLayout } from '../../utility/LayoutContext.js';
import CurrencyLogos from '../../configs/currencyLogos.js'
import moment from 'moment';
import './board.css'
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { useGetData } from '../../hooks/serviceGetways';

const AllCurrencyList = (props) => {
    const { settings, liveDatas } = useContext(ContextLayout)
    const { id } = useParams()
    const getData = useGetData()
    const handle = useFullScreenHandle();
    const [clockState, setClockState] = useState();
    const [size, setSize] = useState(null);
    const [logo, setLogo] = useState(null);
    const [weather, setWeather] = useState(null);
    const api = {
        key: "fbc0e225b268727b4ba01f6840d43112",
        base: "https://api.openweathermap.org/data/2.5/"
    }

    const weatherStatus = {
        "clear sky": { name: "Güneşli", icon: Sunny },
        "few clouds": { name: "Parçalı Bulutlu", icon: CloudyDay },
        "overcast clouds": { name: "Bulutlu", icon: Overcast }
    }

    const onClickHandler = () => {
        fetch(`${api.base}weather?q=${"Çorum"}&units=metric&APPID=${api.key}`)
            .then(res => res.json())
            .then(result => {
                setWeather({ temp: Math.round(result?.main?.temp), status: result?.weather[0]?.description });

            });
    }

    useEffect(() => {
        onClickHandler();
        if (id)
            getData("boards/" + id, {}).then(({ result, resultMessage }) => {
                setLogo(result.logo)
            })
        if (settings) {
            setInterval(() => {
                const date = moment().toDate();
                setClockState(date);
            }, 1000);


            let getSize = () => {
                setSize(Math.round((window.innerHeight) * 1.8 / (settings.currencies.filter(c => c.viewBoard).length)))
            }

            getSize()
            window.addEventListener("resize", getSize)
            return () => {
                window.removeEventListener('resize', getSize)
                window.removeEventListener('load', getSize)

            }
        }
    }, [settings, id])


    if (!liveDatas.length || !settings || !size || !logo)
        return <div className="vw-100 vh-100 d-flex justify-content-center align-items-center"><Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 64 }} spin />} ></Spin></div>
    return (<FullScreen handle={handle}>
        <FullScreen handle={handle}>
            <section className="currency-main">
                <div className="currency-header">
                    <div className="currency-weather">
                        <img src={weatherStatus[weather.status]?.icon}></img>
                        <div className="currency-weather-text">
                            <span className="currency-weather-heat">{weather.temp}°C</span>
                            <span className="location">{weatherStatus[weather.status]?.name}</span>
                        </div>
                    </div>
                    <div className="currency-logo">
                        <img src={logo.url}></img>
                    </div>
                    <div className="currency-date">
                        <span className="currency-time">{moment(clockState).format("HH:mm:ss")}</span>
                        <span className="currency-day">{moment(clockState).format("DD.MM.YYYY")}</span>
                    </div>
                </div>
                <div className="currency-container">
                    <div className="currency-detail">
                        {settings.currencies && settings.currencies.filter(c => c.type === "doviz" && c.viewBoard).map(currency => {
                            const live = liveDatas.find(liveData => currency.key === liveData.key)
                            return (<div key={currency._id} style={{ height: size }} className={classNames("currency-cell", live?.status)}>
                                <div className="currency-info">
                                    <img src={currency.logo}></img>
                                    <div className="currency">
                                        <span className="currency-title">{currency.boardName}</span>
                                        <span className="currency-label">{currency.title}</span>
                                    </div>
                                </div>
                                <div className="currency-status">
                                    <img src={live?.differance == 0 ? STABIL : live?.differance > 0 ? UP : DOWN}></img>
                                    <span>{live?.differance}</span>
                                </div>
                                <div className="currency-variable">
                                    <span>{parseFloat(live?.salesPrice).toFixed(3)}</span>
                                    <span>{parseFloat(live?.purchasePrice).toFixed(3)}</span>
                                </div>
                            </div>)
                        })}
                    </div>
                    <div className="currency-detail">
                        {settings.currencies && settings.currencies.filter(c => c.type === "emtia" && c.viewBoard).map(currency => {
                            const live = liveDatas.find(liveData => currency.key === liveData.key)
                            return (<div key={currency._id} style={{ height: size }} className={classNames("currency-cell", live?.status)}>
                                <div className="currency-info">
                                    <img src={currency.logo}></img>
                                    <div className="currency">
                                        <span className="currency-title">{currency.boardName}</span>
                                        <span className="currency-label">{currency.title}</span>
                                    </div>
                                </div>
                                <div className="currency-status">
                                    <img src={live?.differance == 0 ? STABIL : live?.differance > 0 ? UP : DOWN}></img>
                                    <span>{live?.differance}</span>
                                </div>
                                <div className="currency-variable">
                                    <span>{parseFloat(live?.salesPrice).toFixed(3)}</span>
                                    <span>{parseFloat(live?.purchasePrice).toFixed(3)}</span>
                                </div>
                            </div>)
                        })}
                    </div>
                </div>
            </section>
            <Button className="fullscreen-btn" onClick={handle.active ? handle.exit : handle.enter}>
                {handle.active ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
            </Button>
        </FullScreen>
    </FullScreen>);
}
export default AllCurrencyList
