import React, { useState, useCallback, useEffect } from 'react'
import { Button, Divider, Input, Popconfirm, Table, Modal, Form, message, Switch } from 'antd'
import SingleFileUpload from '../../components/SingleFileUpload'
import { Label } from 'reactstrap'
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, EditOutlined, PlusCircleFilled } from '@ant-design/icons'
import { useDeleteData, useGetData, usePostData, usePutData, useUploadFile } from '../../hooks/serviceGetways'
let { Column } = Table


let EditModal = (props) => {
    let { record, setRecord } = props
    let postData = usePostData()
    let putData = usePutData()
    let [upload, progress] = useUploadFile()

    let validate = useCallback(() => {
        if (!record) return {};
        let errors = {};
        if (record.companyCode == null || record.companyCode.length === 0)
            errors.companyCode = 'Zorunlu Alan!'

        if (record.companyName == null || record.companyName.length < 2)
            errors.companyName = 'Zorunlu Alan!'

        if (record.logo == null || !record.logo || record.uid || record._url)
            errors.logo = 'Zorunlu Alan!'

        if (record.active == null || record.active.length === 0)
            errors.active = 'Zorunlu Alan!'

        errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
        errors.hasError = errors.all.length > 0;
        return errors;
    }, [record]);

    let [errors, setErrors] = useState({})

    useEffect(() => { setErrors(validate()) }, [record, validate]);

    let uploadMedia = async () => {
        let { result, resultMessage } = await upload({ files: [record.logo] })
        if (result)
            return result[0]
        else
            message.error(resultMessage)
    }

    let save = async () => {
        let logo = record.logo && record.logo.uid ? await uploadMedia() : record.logo
        if (record._id === "new")
            postData("boards", { ...record, logo }).then(({ result, resultMessage }) => {
                if (result)
                    message.success("Kayıt başarılı bir şekilde eklendi.")
                setRecord(null)
            })
        else
            putData("boards", { ...record, logo }).then(({ result, resultMessage }) => {
                if (result)
                    message.success("Kayıt başarılı bir şekilde güncellendi.")
                setRecord(null)
            })

    }
    return (
        <Modal title={record && record._id === "new" ? "Pano Ekle" : "Pano Düzenle"}
            okText={record && record._id === "new" ? "Ekle" : "Düzenle"}
            cancelText="Vazgeç"
            visible={true}
            style={{ width: "45%", minWidth: 800 }}
            onOk={save}
            footer={[
                <Button key="1" type="default" onClick={() => setRecord(null)}>
                    Vazgeç
                </Button>,
                <Button key="2" type="primary" onClick={save}
                    disabled={errors.hasError}>
                    {record && record._id === "new" ? "Ekle" : "Düzenle"}
                </Button>

            ]}
            onCancel={() => setRecord(null)}>
            <Form labelCol={{ xs: 4 }} wrapperCol={{ xs: 20 }} labelAlign="left">
                <Form.Item key="1" label='Kod' help={errors ? errors.companyCode : ""} validateStatus={errors && errors.companyCode ? 'error' : 'success'}>
                    <Input value={record?.companyCode}
                        required={true}
                        onChange={e => setRecord({ ...record, companyCode: (e.target.value).replaceAll(/\s/g, '') })} />
                </Form.Item>

                <Form.Item key="4" label='Firma Adı' help={errors ? errors.companyName : ""} validateStatus={errors && errors.companyName ? 'error' : 'success'}>
                    <Input value={record?.companyName}
                        required={true}
                        onChange={e => setRecord({ ...record, companyName: e.target.value })} />
                </Form.Item>

                <Form.Item key="3" label='Aktif' help={errors ? errors.active : ""} validateStatus={errors && errors.active ? 'error' : 'success'}>
                    <Switch checked={record.active} checkedChildren="Aktif" unCheckedChildren="Pasif" onChange={(v) => setRecord({ ...record, active: v })} />
                </Form.Item>

                <Form.Item key="2" label='Logo' help={errors ? errors.logo : ""} validateStatus={errors && errors.logo ? 'error' : 'success'}>
                    <SingleFileUpload record={record} setRecord={setRecord} name="logo" />
                </Form.Item>
            </Form>
        </Modal>
    )
}

const EditPage = () => {
    let [datas, setDatas] = useState([])
    let [record, setRecord] = useState(null)
    let [loading, setLoading] = useState(false)
    let getData = useGetData()
    let deleteData = useDeleteData()

    let deleted = (id) => {
        deleteData("boards/" + id, {}).then(({ result }) => {
            if (result) {
                setLoading(true)
                getData("boards", {}).then((data) => {
                    setDatas(data.result)
                    setLoading(false)
                })
                message.success("Kayıt başarılı bir şekilde silindi.")
            }
        })
    }


    useEffect(() => {
        if (record === null) {
            setLoading(true)
            getData("boards", {}).then(({ result, resultMessage }) => {
                setDatas(result)
                setLoading(false)
            })
        }
    }, [record])

    return (<>
        {record && <EditModal record={record} setRecord={setRecord}></EditModal>}
        <div className="d-flex align-items-center justify-content-end mb-1">
            <Button icon={<PlusCircleFilled />} onClick={() => setRecord({ _id: "new", logo: null, active: true })}> Pano Ekle</Button>
        </div>

        <div className="d-block">
            <Table dataSource={datas} rowKey="_id" pagination={true} loading={loading}>
                <Column dataIndex="logo" key="logo" title="Logo" width="200" align="center" render={(val, record) => <img src={val.thumb} style={{ width: 40, height: 40, borderRadius: 10 }}></img>}></Column>
                <Column dataIndex="companyCode" key="companyCode" title="Kod" align="center" render={(val) => <a style={{ color: '#0054A1',fontWeight:"bold" }} target="_blank" href={"./panel/" + val}>{val}</a>}></Column>
                <Column dataIndex="companyName" key="companyName" title="Firma Adı" align="center"></Column>
                <Column dataIndex="active" key="active" title="Durumu" align="center" render={(val, record) => val ? <CheckCircleOutlined style={{ fontSize: 18 }} /> : <CloseCircleOutlined style={{ fontSize: 18 }} />}></Column>
                <Column dataIndex="edit" key="edit" title="Düzenle" align="center" width="150px" render={(text, recordData) => (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button icon={<EditOutlined />} onClick={() => { setRecord(recordData) }}></Button>
                        <Divider type="vertical" ></Divider>
                        <Popconfirm
                            onConfirm={() => deleted(recordData._id)} title="Silmeyi Onaylıyor musunuz?"
                            okText="Onayla" cancelText="Vazgeç">
                            <Button type="danger" icon={<DeleteOutlined />}>
                            </Button>
                        </Popconfirm>
                    </div>
                )}></Column>
            </Table>

        </div>
    </>
    )
}

export default EditPage
