import React, { useState, useCallback, useEffect } from 'react'
import { Button, Divider, Input, Popconfirm, Table, Modal, Form, message, Switch, Select, InputNumber, Tag } from 'antd'
import SingleFileUpload from '../../components/SingleFileUpload'
import { Label } from 'reactstrap'
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, EditOutlined, PlusCircleFilled } from '@ant-design/icons'
import { useDeleteData, useGetData, usePostData, usePutData, useUploadFile } from '../../hooks/serviceGetways'
let { Column } = Table
let { Option } = Select


let EditModal = (props) => {
    let { record, setRecord } = props
    let postData = usePostData()
    let putData = usePutData()
    let [upload, progress] = useUploadFile()

    let validate = useCallback(() => {
        if (!record) return {};
        let errors = {};
       
        if (record.key == null || record.key.length === 0)
            errors.key = 'Zorunlu Alan!'

        if (record.type == null || record.type.length === 0)
            errors.type = 'Zorunlu Alan!'

        if (record.operationType == null || record.operationType.length === 0)
            errors.operationType = 'Zorunlu Alan!'


        if (record.salesRatio == null || record.salesRatio.length === 0)
            errors.salesRatio = 'Zorunlu Alan!'

        if (record.purchaseRatio == null || record.purchaseRatio.length === 0)
            errors.purchaseRatio = 'Zorunlu Alan!'

        // if (record.logo == null || !record.logo || record.uid || record._url)
        //     errors.image = 'Zorunlu Alan!'

        if (record.active == null || record.active.length === 0)
            errors.active = 'Zorunlu Alan!'

        if (record.viewPersonel == null || record.viewPersonel.length === 0)
            errors.viewPersonel = 'Zorunlu Alan!'

        if (record.viewBoard == null || record.viewBoard.length === 0)
            errors.viewBoard = 'Zorunlu Alan!'

        if (record.viewMobile == null || record.viewMobile.length === 0)
            errors.viewMobile = 'Zorunlu Alan!'

        if (record.logo == null || !record.logo || record.uid || record._url)
            errors.logo = 'Zorunlu Alan!'


        errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
        errors.hasError = errors.all.length > 0;
        return errors;
    }, [record]);

    let [errors, setErrors] = useState({})

    useEffect(() => { setErrors(validate()) }, [record, validate]);

    let uploadMedia = async () => {
        let { result, resultMessage } = await upload({ files: [record.logo] })
        if (result)
            return result[0]
        else
            message.error(resultMessage)
    }

    let save = async () => {
        let logo = record.logo && record.logo.uid ? await uploadMedia() : record.logo
        if (record._id)
            putData("currencies", { ...record, logo: logo }).then(({ result, resultMessage }) => {
                if (result)
                    message.success("Kayıt başarılı bir şekilde güncellendi.")
                setRecord(null)
            })
        else
            postData("currencies", { ...record, logo: logo }).then(({ result, resultMessage }) => {
                if (result)
                    message.success("Kayıt başarılı bir şekilde eklendi.")
                setRecord(null)
            })

    }
    return (
        <Modal title={record && record._id === "new" ? "Döviz/Emita Ekle" : "Döviz/Emita Düzenle"}
            okText={record && !record._id ? "Ekle" : "Düzenle"}
            cancelText="Vazgeç"
            visible={true}
            onOk={save}
            style={{ width: "45%", minWidth: 800 }}
            footer={[
                <Button key="1" type="default" onClick={() => setRecord(null)}>
                    Vazgeç
                </Button>,
                <Button key="2" type="primary" onClick={save}
                    disabled={errors.hasError}>
                    {record && !record._id ? "Ekle" : "Düzenle"}
                </Button>

            ]}
            onCancel={() => setRecord(null)}>
            <Form labelCol={{ xs: 4 }} wrapperCol={{ xs: 20 }} labelAlign="left">
                <Form.Item key="1" label='Kısa Adı' help={errors ? errors.key : ""} validateStatus={errors && errors.key ? 'error' : 'success'}>
                    <Input value={record?.key}
                        disabled={record._id ? true : false}
                        required={true}
                        onChange={e => setRecord({ ...record, key: e.target.value })} />
                </Form.Item>

                <Form.Item key="2" label='Uzun Adı' help={errors ? errors.title : ""} validateStatus={errors && errors.title ? 'error' : 'success'}>
                    <Input value={record?.title}
                        required={true}
                        onChange={e => setRecord({ ...record, title: e.target.value })} />
                </Form.Item>

                <Form.Item key="14" label='Pano Adı' help={errors ? errors.boardName : ""} validateStatus={errors && errors.boardName ? 'error' : 'success'}>
                    <Input value={record?.boardName}
                        required={true}
                        onChange={e => setRecord({ ...record, boardName: e.target.value })} />
                </Form.Item>

                <Form.Item key="3" label='Aktif' help={errors ? errors.active : ""} validateStatus={errors && errors.active ? 'error' : 'success'}>
                    <Switch checked={record.active} checkedChildren="Aktif" unCheckedChildren="Pasif" onChange={(v) => setRecord({ ...record, active: v })} />
                </Form.Item>

                <Form.Item key="4" label='Sıra' help={errors ? errors.rank : ""} validateStatus={errors && errors.rank ? 'error' : 'success'}>
                    <InputNumber value={record?.rank}
                        required={true}
                        onChange={v => setRecord({ ...record, rank: v })} />
                </Form.Item>

                <Form.Item key="5" label='Türü' help={errors ? errors.type : ""} validateStatus={errors && errors.type ? 'error' : 'success'}>
                    <Select value={record?.type} onChange={v => setRecord({ ...record, type: v })}>
                        <Option key="1" value="emtia">Altın/Emtia</Option>
                        <Option key="2" value="doviz">Döviz</Option>
                    </Select>
                </Form.Item>

                <Form.Item key="6" label='İşlem Türü' help={errors ? errors.operationType : ""} validateStatus={errors && errors.operationType ? 'error' : 'success'}>
                    <Select value={record?.operationType} onChange={v => setRecord({ ...record, operationType: v })}>
                        <Option key="1" value="sum">Topla</Option>
                        <Option key="2" disabled={record?.type === "doviz" ? true : false} value="multiply">Çarpım</Option>
                    </Select>
                </Form.Item>

                <Form.Item key="7" label='Alış Oranı' help={errors ? errors.salesRatio : ""} validateStatus={errors && errors.salesRatio ? 'error' : 'success'}>
                    <InputNumber value={record?.salesRatio} decimalSeparator=","
                        required={true}
                        onChange={v => setRecord({ ...record, salesRatio: v })} />
                </Form.Item>

                <Form.Item key="8" label='Satış Oranı' help={errors ? errors.purchaseRatio : ""} validateStatus={errors && errors.purchaseRatio ? 'error' : 'success'}>
                    <InputNumber value={record?.purchaseRatio} decimalSeparator=","
                        required={true}
                        onChange={v => setRecord({ ...record, purchaseRatio: v })} />
                </Form.Item>

                <Form.Item key="9" label='Personel' help={errors ? errors.viewPersonel : ""} validateStatus={errors && errors.viewPersonel ? 'error' : 'success'}>
                    <Switch checked={record.viewPersonel} checkedChildren="Aktif" unCheckedChildren="Pasif" onChange={(v) => setRecord({ ...record, viewPersonel: v })} />
                </Form.Item>

                <Form.Item key="10" label='Mobil' help={errors ? errors.viewMobile : ""} validateStatus={errors && errors.viewMobile ? 'error' : 'success'}>
                    <Switch checked={record.viewMobile} checkedChildren="Aktif" unCheckedChildren="Pasif" onChange={(v) => setRecord({ ...record, viewMobile: v })} />
                </Form.Item>

                <Form.Item key="11" label='Pano' help={errors ? errors.viewBoard : ""} validateStatus={errors && errors.viewBoard ? 'error' : 'success'}>
                    <Switch checked={record.viewBoard} checkedChildren="Aktif" unCheckedChildren="Pasif" onChange={(v) => setRecord({ ...record, viewBoard: v })} />
                </Form.Item>

                <Form.Item key="12" label='Logo' help={errors ? errors.logo : ""} validateStatus={errors && errors.logo ? 'error' : 'success'}>
                    <SingleFileUpload record={record} setRecord={setRecord} name="logo" />
                </Form.Item>
            </Form>
        </Modal>
    )
}

const EditPage = () => {
    let [currencies, setCurrencies] = useState([])
    let [page, setPage] = useState(0)
    let [record, setRecord] = useState(null)
    let [loading, setLoading] = useState(false)
    let getData = useGetData()
    let deleteData = useDeleteData()

    let deleted = (id) => {
        deleteData("slider/" + id, {}).then(({ result }) => {
            if (result) {
                setLoading(true)
                getData("slider", {}).then((data) => {
                    setCurrencies(data.result)
                    setLoading(false)
                })
                message.success("Kayıt başarılı bir şekilde silindi.")
            }
        })
    }

    useEffect(() => {
        if (record === null) {
            setLoading(true)
            getData("currencies", {}).then(({ result, resultMessage }) => {
                if (result) {
                    setCurrencies(result)
                    setLoading(false)
                }
            })

        }
    }, [record])

    return (<>
        {record && <EditModal record={record} setRecord={setRecord}></EditModal>}
        <div className="d-flex align-items-center justify-content-end mb-1">
            <Button icon={<PlusCircleFilled />} onClick={() => setRecord({ viewBoard: false, viewMobile: false, viewPersonel: false, active: true })}>Döviz/Emtia Ekle</Button>
        </div>

        <div className="d-block">
            <Table dataSource={currencies} size="small" rowKey="_id" pagination={false} loading={loading}>
                <Column dataIndex="rank" key="rank" title="Sıra" width="3%" align="center"></Column>
                <Column dataIndex="logo" key="logo" title="Logo" width="5%" align="center" render={(val, record) => <img src={val?.url} style={{ width: 40, height: 40, borderRadius: 10 }}></img>}></Column>
                <Column dataIndex="key" key="key" title="Kısa Adı" width="8%" align="center"></Column>
                <Column dataIndex="boardName" key="boardName" title="Pano Adı" width="8%" align="center"></Column>
                <Column dataIndex="title" key="title" title="Uzun Adı" width="10%" align="center" />
                <Column dataIndex="active" key="active" title="Durumu" align="center" width="5%" render={(val, record) => val ? <Tag className="p-2" color="green"><CheckCircleOutlined style={{ fontSize: 18 }} /> </Tag> : <Tag className="p-2" color="volcano"><CloseCircleOutlined style={{ fontSize: 18 }} /></Tag>}></Column>
                <Column dataIndex="type" key="type" title="Türü" width="7%" align="center" render={(val) => val === "emtia" ? "ALTIN/Emtia" : val === "doviz" ? "Döviz" : ""}></Column>
                <Column dataIndex="operationType" key="operationType" title="İşlem Türü" width="7%" align="center" render={(val) => val === "sum" ? "Topla" : val === "multiply" ? "Çarpım" : ""}></Column>
                <Column dataIndex="salesRatio" key="salesRatio" title="Satış Oranı" width="7%" align="center"></Column>
                <Column dataIndex="purchaseRatio" key="purchaseRatio" title="Alış Oranı" width="7%" align="center"></Column>
                <Column dataIndex="viewPersonel" key="viewPersonel" title="Personel" width="7%" align="center" render={(val, record) => val ? <Tag className="p-2" color="green"><CheckCircleOutlined style={{ fontSize: 18 }} /></Tag> : <Tag className="p-2" color="volcano"><CloseCircleOutlined style={{ fontSize: 18 }} /></Tag>}></Column>
                <Column dataIndex="viewMobile" key="viewMobile" title="Mobil" width="7%" align="center" render={(val, record) => val ? <Tag className="p-2" color="green"><CheckCircleOutlined style={{ fontSize: 18 }} /></Tag> : <Tag className="p-2" color="volcano"><CloseCircleOutlined style={{ fontSize: 18 }} /></Tag>}></Column>
                <Column dataIndex="viewBoard" key="viewBoard" title="Pano" width="7%" align="center" render={(val, record) => val ? <Tag className="p-2" color="green"><CheckCircleOutlined style={{ fontSize: 18 }} /></Tag> : <Tag className="p-2" color="volcano"><CloseCircleOutlined style={{ fontSize: 18 }} /></Tag>}></Column>

                <Column dataIndex="edit" key="edit" title="Düzenle" align="center" width="5%" render={(text, recordData) => (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button icon={<EditOutlined />} onClick={() => { setRecord(recordData) }}></Button>
                    </div>
                )}></Column>
            </Table>

        </div>
    </>
    )
}

export default EditPage
