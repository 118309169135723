import ALTIN from '../assets/images/ALTIN.png'
import AUDTRY from '../assets/images/AUDTRY.png'
import BILEZIK14 from '../assets/images/BILEZIK14.png'
import BILEZIK22 from '../assets/images/BILEZIK22.png'
import CADTRY from '../assets/images/CADTRY.png'
import CEYREK from '../assets/images/CEYREK.png'
import CHFTRY from '../assets/images/CHFTRY.png'
import DKKTRY from '../assets/images/DKKTRY.png'
import EURTRY from '../assets/images/EURTRY.png'
import GBPTRY from '../assets/images/GBPTRY.png'
import JPYTRY from '../assets/images/JPYTRY.png'
import NOKTRY from '../assets/images/NOKTRY.png'
import ONS from '../assets/images/ONS.png'
import RESAT from '../assets/images/RESAT.png'
import SARTRY from '../assets/images/SARTRY.png'
import SEKTRY from '../assets/images/SEKTRY.png'
import USDTRY from '../assets/images/USDTRY.png'
import EURUSD from '../assets/images/EURUSD.png'
import CNYTRY from '../assets/images/CNYTRY.png'
import GUMUSTRY from '../assets/images/GUMUSTRY.png'
import GUMUSUSD from '../assets/images/GUMUSUSD.png'
import PLATIN from '../assets/images/PLATIN.png'
import YARIM from '../assets/images/YARIM.png'
import SAMLIOGLU from '../assets/images/SAMLIOGLU.png'
import STABIL from '../assets/images/STABIL.png'
import DOWN from '../assets/images/DOWN.png'
import UP from '../assets/images/UP.png'
import GRUPLOGO from '../assets/images/GRUPLOGO.png'

export default { ALTIN, AUDTRY, BILEZIK14, BILEZIK22, CADTRY, CEYREK, CHFTRY, DKKTRY, EURTRY, GBPTRY, JPYTRY, NOKTRY, ONS, RESAT, SARTRY, SEKTRY, USDTRY, YARIM, CNYTRY, EURUSD, GUMUSTRY, GUMUSUSD, PLATIN, SAMLIOGLU, STABIL, DOWN, UP, GRUPLOGO }